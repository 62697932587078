import { createI18n } from 'vue-i18n'

import en from '@/locales/en.js';
import he from '@/locales/he.js';



const messages = {
  en,
  he
  // import other languages here
};

const i18n = new createI18n({
  locale: 'he', // set default locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});

export default i18n;
