import { createRouter, createWebHashHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";

export const FLOW_ROUTE_NAME = "flow";
const DEFAULT_PAGE = "/cpt/home";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: DEFAULT_PAGE
  },
  {
    path: "/cpt/",
    // this will load the TabsPage component
    component: () => import("@/views/TabsPage.vue"),
    children: [
      {
        path: "",
        redirect: DEFAULT_PAGE
      },
      {
        path: "home",
        component: () => import("@/views/HomePage.vue")
      },
      {
        path: "flow/:flowName/:answerIndex?/:stepIndex?",
        name: FLOW_ROUTE_NAME,
        component: () => import("@/views/GeneralComponentTpl.vue"),
        props: (route) => ({
          componenetPath: "Flow", // Extract the componenetPath from the route params
          title: "תרגול נקודת תקיעות" // You can provide other default values here
        })
      },
      {
        path: "stuckpoints",
        component: () => import("@/components/StuckPoints.vue")
      },
      {
        path: "Journey",
        component: () => import("@/components/Journey.vue")
      },
      {
        path: "Questionnaire/:qName?",
        component: () => import("@/components/Questionnaire.vue")
      },
      {
        path: "CptList",
        component: () => import("@/views/GeneralComponentTpl.vue"),
        props: (route) => ({
          componenetPath: "CptList", // Extract the componenetPath from the route params
          title: "רשימת מבחנים שבוצעו" // You can provide other default values here
        })
      },
      {
        path: "test/:componenetPath",
        component: () => import("@/views/GeneralComponentTpl.vue"),
        props: (route) => ({
          componenetPath: route.params.componenetPath, // Extract the componenetPath from the route params
          title: "Test Component" // You can provide other default values here
        })
      },
      { path: "finishSuccess", component: () => import("@/views/FinishSuccess.vue"), name: "finishSuccess" }
    ]
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound404",
    component: import("@/views/NotFound404.vue")
  }
];

// @ts-ignore
const baseUrl = import.meta?.env?.BASE_URL || "/";
const router = createRouter({
  history: createWebHashHistory(baseUrl),
  routes
});

export default router;
