<template>
<ion-app>
    <ion-router-outlet />
</ion-app>
</template>

<script lang="ts">
import {
    IonApp,
    IonRouterOutlet
} from '@ionic/vue';
import {
    defineComponent
} from 'vue';
export default defineComponent({
    name: 'App', // Replace with your component name
    components: {
        IonApp,
        IonRouterOutlet,
    },
});
</script>
